#main.create-company {
  overflow: hidden; }

.modal-create-company {
  box-shadow: 0px 0px 0px !important;
  border-radius: 0px !important; }
  .modal-create-company .strengthmeter .meter.segments {
    border: 0px;
    box-shadow: none; }
    .modal-create-company .strengthmeter .meter.segments > .segment {
      border-bottom: 4px solid #e6e6e6;
      margin-right: 2px;
      margin-left: 2px; }
  .modal-create-company .strengthmeter label {
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 26px; }
  .modal-create-company .strengthmeter.vweak label.vweak {
    display: block;
    opacity: 0; }
  .modal-create-company .strengthmeter.vweak label.weak {
    display: none; }
  .modal-create-company .strengthmeter.vweak label.soso {
    display: none; }
  .modal-create-company .strengthmeter.vweak label.good {
    display: none; }
  .modal-create-company .strengthmeter.vweak label.great {
    display: none; }
  .modal-create-company .strengthmeter.weak .meter.segments > .segment.first {
    border-bottom: 4px solid #c81818; }
  .modal-create-company .strengthmeter.weak label.vweak {
    display: none; }
  .modal-create-company .strengthmeter.weak label.weak {
    display: block;
    color: #c81818; }
  .modal-create-company .strengthmeter.weak label.soso {
    display: none; }
  .modal-create-company .strengthmeter.weak label.good {
    display: none; }
  .modal-create-company .strengthmeter.weak label.great {
    display: none; }
  .modal-create-company .strengthmeter.soso .meter.segments > .segment.first,
  .modal-create-company .strengthmeter.soso .meter.segments > .segment.second {
    border-bottom: 4px solid #ffac1d; }
  .modal-create-company .strengthmeter.soso label.vweak {
    display: none; }
  .modal-create-company .strengthmeter.soso label.weak {
    display: none; }
  .modal-create-company .strengthmeter.soso label.soso {
    display: block;
    color: #ffac1d; }
  .modal-create-company .strengthmeter.soso label.good {
    display: none; }
  .modal-create-company .strengthmeter.soso label.great {
    display: none; }
  .modal-create-company .strengthmeter.good .meter.segments > .segment.first,
  .modal-create-company .strengthmeter.good .meter.segments > .segment.second,
  .modal-create-company .strengthmeter.good .meter.segments > .segment.third {
    border-bottom: 4px solid #a6c060; }
  .modal-create-company .strengthmeter.good label.vweak {
    display: none; }
  .modal-create-company .strengthmeter.good label.weak {
    display: none; }
  .modal-create-company .strengthmeter.good label.soso {
    display: none; }
  .modal-create-company .strengthmeter.good label.good {
    display: block;
    color: #a6c060; }
  .modal-create-company .strengthmeter.good label.great {
    display: none; }
  .modal-create-company .strengthmeter.great .meter.segments > .segment.first,
  .modal-create-company .strengthmeter.great .meter.segments > .segment.second,
  .modal-create-company .strengthmeter.great .meter.segments > .segment.third,
  .modal-create-company .strengthmeter.great .meter.segments > .segment.fourth {
    border-bottom: 4px solid #27b30f; }
  .modal-create-company .strengthmeter.great label.vweak {
    display: none; }
  .modal-create-company .strengthmeter.great label.weak {
    display: none; }
  .modal-create-company .strengthmeter.great label.soso {
    display: none; }
  .modal-create-company .strengthmeter.great label.good {
    display: none; }
  .modal-create-company .strengthmeter.great label.great {
    display: block;
    color: #27b30f; }
  .modal-create-company .hint {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    letter-spacing: 0; }
    .modal-create-company .hint .available {
      color: #208a19; }
    .modal-create-company .hint .taken {
      color: #f5a623; }
  .modal-create-company .ui.labeled.input.skejule > .label {
    background: rgba(255, 255, 255, 0);
    color: #3765b3;
    padding-left: 5px;
    font-weight: bolder;
    margin-right: 2em; }
  .modal-create-company .ui.labeled.input.skejule input {
    border-top-right-radius: 0.28571429rem !important;
    border-bottom-right-radius: 0.28571429rem !important;
    padding-right: 10px !important;
    text-align: right; }
  .modal-create-company .ui.labeled.input.skejule .icon {
    opacity: 0; }
  .modal-create-company .ui.labeled.input.skejule.checking-subdomain .icon {
    opacity: 1; }
  .modal-create-company .ui.button {
    font-size: 14px;
    line-height: 1.42857143; }
  .modal-create-company .ui.button:disabled, .modal-create-company .ui.disabled.button {
    opacity: 0.4 !important;
    background: #999; }
  .modal-create-company .ui.input {
    font-size: 14px;
    line-height: 1.42857143; }
    .modal-create-company .ui.input input:focus {
      border-color: #74c0d0 !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }
    .modal-create-company .ui.input img.icon-x {
      position: absolute;
      top: 0;
      right: 10px;
      height: 100%;
      width: 1em;
      border-radius: 0 0.28571429rem 0.28571429rem 0;
      -webkit-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
