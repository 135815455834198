@charset "UTF-8";
@import url(../node_modules/semantic-ui-css/semantic.min.css);
@import url(../node_modules/slick-carousel/slick/slick.css);
@import url(../node_modules/slick-carousel/slick/slick-theme.css);
.skejule-blue {
  color: #3765b3 !important; }

.skejule-brown {
  color: #f5a623 !important; }

.skejule-teal {
  color: #74c0d0 !important; }

.white {
  color: #ffffff !important; }

body.no-website {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #999999;
  letter-spacing: 0.3px;
  line-height: 24px;
  background: transparent url("./images/fondo.png") no-repeat center center;
  background-size: cover; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #77c0cf; }

button,
input,
optgroup,
select,
textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
  padding: 12px 20px;
  border-radius: 100px !important; }

textarea {
  border-radius: 10px !important; }

input,
optgroup,
select,
textarea {
  color: #999999 !important; }
  input:focus,
  optgroup:focus,
  select:focus,
  textarea:focus {
    background: #fff !important; }
    input:focus::placeholder,
    optgroup:focus::placeholder,
    select:focus::placeholder,
    textarea:focus::placeholder {
      opacity: 0.5; }

button.disabled {
  color: #fff !important; }

.ui.modal {
  padding: 20px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5);
  background: transparent; }
  .ui.modal > .header {
    text-align: center;
    border-bottom: 0px; }
  .ui.modal h1 {
    font-size: 25px;
    color: #77c0cf;
    letter-spacing: 1.33px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center; }
  .ui.modal h4 {
    font-size: 18px;
    color: #bdbbbb;
    letter-spacing: 0.3px;
    line-height: 16px;
    margin-top: 0px;
    text-align: center; }
  @media (max-width: 767px) {
    .ui.modal.seriously-fullscreen-mobile {
      width: 100vw !important;
      height: 100vh !important;
      margin: 0 auto;
      margin-top: 0px;
      box-shadow: 0px 0px 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      z-index: 1000 !important;
      left: 0 !important;
      padding: 10px 20px !important; } }

@media (min-width: 768px) {
  .ui.modal {
    padding: 45px 85px; } }

.ui.input, .ui.button, .uibutton {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 15px; }

.ui.skejule.button,
.ui.skejule.buttons button {
  background-color: #77c0cf;
  color: #fff;
  text-shadow: none;
  background-image: none; }

.skejule-scroll {
  width: 100%;
  overflow-y: auto;
  height: 70vh;
  /*70vh*/
  border-top: 1px solid rgba(0, 0, 0, 0.05); }
  .skejule-scroll::-webkit-scrollbar {
    width: 5px !important;
    background: #dcdcdc !important; }
  .skejule-scroll::-webkit-scrollbar-track {
    background: #dcdcdc !important; }
  .skejule-scroll::-webkit-scrollbar-thumb {
    background: #74c0d0 !important; }

/******************* CUSTOM FONT SIZE ********************/
.bigtext {
  font-size: 18px !important;
  font-weight: bold !important; }

.tinytext {
  font-size: 11px !important; }

/******************* CUSTOM FONT SIZE ********************/
/******************* TOP MOBILE MENU BAR ********************/
.menubar {
  position: fixed;
  width: 100%;
  height: 50px;
  top: 0px;
  left: 0px;
  z-index: 1001;
  background: #6ac7da;
  background: -webkit-linear-gradient(#6ac7da 0%, #56ceaa 100vh);
  background: -o-linear-gradient(#6ac7da 0%, #56ceaa 100vh);
  background: -moz-linear-gradient(#6ac7da 0%, #56ceaa 100vh);
  background: linear-gradient(#6ac7da 0%, #56ceaa 100vh); }
  .menubar .trigger {
    top: 0px;
    left: 0px;
    position: absolute;
    padding: 9px 24px;
    cursor: pointer; }
  .menubar .search,
  .menubar .back {
    top: 0px;
    right: 0px;
    position: absolute;
    padding: 9px 24px;
    cursor: pointer; }
  .menubar .title {
    color: #ffffff !important;
    text-align: center !important;
    font-size: 20px !important;
    letter-spacing: 0.3px !important;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 50px; }

/******************* TOP MOBILE MENU BAR ********************/
/******************* SIDEBAR MENU ********************/
.sidebarmenu {
  width: 82px;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  left: -82px;
  top: 0px;
  background: #6ac7da;
  background: -webkit-linear-gradient(#6ac7da, #56ceaa);
  background: -o-linear-gradient(#6ac7da, #56ceaa);
  background: -moz-linear-gradient(#6ac7da, #56ceaa);
  background: linear-gradient(#6ac7da, #56ceaa);
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s; }
  .sidebarmenu.visible {
    left: 0px;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s; }
  @media (min-width: 769px) {
    .sidebarmenu {
      left: 0px !important; } }
  .sidebarmenu .ui.menu .item {
    background: none;
    padding: 0px;
    margin: 0px 13px;
    min-width: 32px !important;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px; }
    .sidebarmenu .ui.menu .item:first-child {
      padding-top: 50px; }
    .sidebarmenu .ui.menu .item:before {
      background: none; }
    .sidebarmenu .ui.menu .item.active {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff; }
  .sidebarmenu .user-avatar {
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: 44px;
    height: 44px;
    margin-left: -22px; }

/******************* SIDEBAR MENU ********************/
/******************* MESSAGE BOARD ********************/
.message-board .message {
  min-height: 32px;
  padding: 5px 32px;
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.3px;
  width: 100%;
  margin: -10px 0 !important; }
  .message-board .message .header {
    margin-right: 5px;
    font-family: unset;
    font-size: 14px !important;
    letter-spacing: 0.3px; }
    .message-board .message .header + p {
      margin-top: -2px; }
  .message-board .message span {
    display: unset !important; }
  .message-board .message > .close.icon {
    top: 6px; }
  .message-board .message.info {
    color: #74c0d0;
    box-shaddow: 0 0 0 1px #74c0d0 inset, 0 0 0 0 transparent;
    background: #e3f2f6 url("images/message-info.svg") no-repeat 7px 7px;
    background-size: 20px; }
    .message-board .message.info .header {
      color: #74c0d0; }
  .message-board .message.warning {
    color: #f5a623;
    box-shaddow: 0 0 0 1px #f5a623 inset, 0 0 0 0 transparent;
    background: #eadfd6 url("images/message-warning.svg") no-repeat 7px 7px;
    background-size: 20px; }
    .message-board .message.warning .header {
      color: #f5a623; }
  .message-board .message.negative {
    color: #c52626;
    box-shaddow: 0 0 0 1px #c52626 inset, 0 0 0 0 transparent;
    background: #e9caca url("images/message-negative.svg") no-repeat 7px 7px;
    background-size: 20px; }
    .message-board .message.negative .header {
      color: #c52626; }
  .message-board .message.success {
    color: #208a19;
    box-shaddow: 0 0 0 1px #208a19 inset, 0 0 0 0 transparent;
    background: #e0eed7 url("images/message-success.svg") no-repeat 7px 7px;
    background-size: 20px; }
    .message-board .message.success .header {
      color: #208a19; }

/******************* MESSAGE BOARD ********************/
/******************* INPUT FIELD THEMING ********************/
.ui .info {
  color: #74c0d0; }
  .ui .info input {
    color: #74c0d0;
    border: 1px solid #74c0d0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6);
    border-radius: 100px; }

.ui .warning {
  color: #f5a623; }
  .ui .warning input {
    color: #f5a623;
    border: 1px solid #f5a623;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 166, 35, 0.6);
    border-radius: 100px; }

.ui .negative {
  color: #c52626; }
  .ui .negative input {
    color: #c52626;
    border: 1px solid #c52626;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(197, 38, 38, 0.6);
    border-radius: 100px; }

.ui .success {
  color: #208a19; }
  .ui .success input {
    color: #208a19;
    border: 1px solid #208a19;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(32, 138, 25, 0.6);
    border-radius: 100px; }

.ui .labeled.info input {
  border-right-color: #74c0d0 !important; }

.ui .labeled.warning input {
  border-right-color: #f5a623 !important; }

.ui .labeled.negative input {
  border-right-color: #c52626 !important; }

.ui .labeled.success input {
  border-right-color: #208a19 !important; }

.ui .labeled.success .icon {
  color: #208a19 !important;
  opacity: 1 !important; }

.ui .pulse-info {
  box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
  animation: pulso-info 2s infinite;
  border-radius: 4px; }
  .ui .pulse-info.input {
    box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
    animation: none; }
    .ui .pulse-info.input input {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
      animation: pulso-info 2s infinite; }
  .ui .pulse-info.checkbox {
    box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
    animation: none; }
    .ui .pulse-info.checkbox label:before {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
      animation: pulso-info 2s infinite; }

.ui .pulse-info:hover {
  animation: none; }

@-webkit-keyframes pulso-info {
  0% {
    -webkit-box-shadow: 0 0 0 0 #74c0d0; }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(116, 192, 208, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(116, 192, 208, 0); } }

@keyframes pulso-info {
  0% {
    -moz-box-shadow: 0 0 0 0 #74c0d0;
    box-shadow: 0 0 0 0 #74c0d0; }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(116, 192, 208, 0);
    box-shadow: 0 0 0 10px rgba(116, 192, 208, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
    box-shadow: 0 0 0 0 rgba(116, 192, 208, 0); } }

.ui .pulse-warning {
  box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
  animation: pulso-warning 2s infinite;
  border-radius: 4px; }
  .ui .pulse-warning.input {
    box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
    animation: none; }
    .ui .pulse-warning.input input {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
      animation: pulso-warning 2s infinite; }
  .ui .pulse-warning.checkbox {
    box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
    animation: none; }
    .ui .pulse-warning.checkbox label:before {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
      animation: pulso-warning 2s infinite; }

.ui .pulse-warning:hover {
  animation: none; }

@-webkit-keyframes pulso-warning {
  0% {
    -webkit-box-shadow: 0 0 0 0 #c89065; }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(200, 144, 101, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(200, 144, 101, 0); } }

@keyframes pulso-warning {
  0% {
    -moz-box-shadow: 0 0 0 0 #c89065;
    box-shadow: 0 0 0 0 #c89065; }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(200, 144, 101, 0);
    box-shadow: 0 0 0 10px rgba(200, 144, 101, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
    box-shadow: 0 0 0 0 rgba(200, 144, 101, 0); } }

.ui .pulse-negative {
  box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
  animation: pulso-negative 2s infinite;
  border-radius: 4px; }
  .ui .pulse-negative.input {
    box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
    animation: none; }
    .ui .pulse-negative.input input {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
      animation: pulso-negative 2s infinite; }
  .ui .pulse-negative.checkbox {
    box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
    animation: none; }
    .ui .pulse-negative.checkbox label:before {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
      animation: pulso-negative 2s infinite; }

.ui .pulse-negative:hover {
  animation: none; }

@-webkit-keyframes pulso-negative {
  0% {
    -webkit-box-shadow: 0 0 0 0 #c52626; }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(197, 38, 38, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(197, 38, 38, 0); } }

@keyframes pulso-negative {
  0% {
    -moz-box-shadow: 0 0 0 0 #c52626;
    box-shadow: 0 0 0 0 #c52626; }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(197, 38, 38, 0);
    box-shadow: 0 0 0 10px rgba(197, 38, 38, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
    box-shadow: 0 0 0 0 rgba(197, 38, 38, 0); } }

.ui .pulse-success {
  box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
  animation: pulso-success 2s infinite;
  border-radius: 4px; }
  .ui .pulse-success.input {
    box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
    animation: none; }
    .ui .pulse-success.input input {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
      animation: pulso-success 2s infinite; }
  .ui .pulse-success.checkbox {
    box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
    animation: none; }
    .ui .pulse-success.checkbox label:before {
      border-radius: 4px;
      box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
      animation: pulso-success 2s infinite; }

.ui .pulse-success:hover {
  animation: none; }

@-webkit-keyframes pulso-success {
  0% {
    -webkit-box-shadow: 0 0 0 0 #208a19; }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(32, 138, 25, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(32, 138, 25, 0); } }

@keyframes pulso-success {
  0% {
    -moz-box-shadow: 0 0 0 0 #208a19;
    box-shadow: 0 0 0 0 #208a19; }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(32, 138, 25, 0);
    box-shadow: 0 0 0 10px rgba(32, 138, 25, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
    box-shadow: 0 0 0 0 rgba(32, 138, 25, 0); } }

/******************* INPUT FIELD THEMING ********************/
/******************* CUSTOM COLORED LOADER ********************/
.ui.loader:before {
  margin: 0px !important; }

.ui.loader:after {
  border-color: #74c0d0 transparent transparent; }

.ui.loader:before, .ui.loader:after {
  margin: 0px !important; }

.ui.loader.inline {
  top: -2px; }

/******************* CUSTOM COLORED LOADER ********************/
/******************* CUSTOM ICONS ********************/
[class^="icono-"],
[class*=" icono-"] {
  font-size: 26px !important;
  vertical-align: sub !important; }

.icono.tiny {
  font-size: 19px !important; }

.icono.big {
  font-size: 32px !important; }

.icono.huge {
  font-size: 55px !important; }

/******************* CUSTOM ICONS ********************/
/******************* CUSTOM DIVIDER ********************/
.ui.horizontal.divider.skejule-divider {
  color: #9e9d9d;
  text-transform: capitalize;
  letter-spacing: -0.19px;
  display: flex;
  line-height: 25px; }
  .ui.horizontal.divider.skejule-divider:before {
    width: 0% !important;
    background-size: 100% auto; }
  .ui.horizontal.divider.skejule-divider:after {
    width: 100% !important;
    background-size: 100% auto; }

/******************* CUSTOM DIVIDER ********************/
/******************* CUSTOM CONTAINER ********************/
/** Shadow Container **/
.shadowcontainer {
  box-shadow: 0px 2px 8px 0 rgba(217, 217, 217, 0.5);
  border-radius: 9px;
  padding: 15px; }
  .shadowcontainer .innercontainer {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding: 25px;
    min-height: 165px; }
    .shadowcontainer .innercontainer .borderright {
      border-right: 0px;
      border-bottom: 1px solid #e0e0e0;
      margin-left: 14px;
      margin-right: 14px; }
    @media (min-width: 768px) {
      .shadowcontainer .innercontainer .borderright {
        border-right: 1px solid #e0e0e0;
        border-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px; } }

/******************* CUSTOM CONTAINER ********************/
/******************* CUSTOM ATOM METER ********************/
.atom-meter {
  text-align: center; }
  .atom-meter .atom {
    font-size: 30px;
    color: #9e9d9d;
    letter-spacing: 0.3px;
    border: 1px solid #e0e0e0;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 35px;
    line-height: 70px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 8px 0 rgba(217, 217, 217, 0.5);
    position: relative; }
    .atom-meter .atom .electron {
      background: #9e9d9d;
      width: 19px;
      height: 19px;
      border-radius: 19px;
      box-shadow: 0 0 0 5px rgba(158, 157, 157, 0.63);
      box-sizing: content-box;
      position: absolute;
      top: 10px;
      right: -10px; }
  .atom-meter .atom-label {
    font-weight: lighter;
    font-size: 14px;
    color: #9e9d9d;
    letter-spacing: -0.19px;
    text-transform: uppercase; }
  .atom-meter.info .atom {
    border-color: #74c0d0 !important; }
    .atom-meter.info .atom .electron {
      background: #74c0d0;
      box-shadow: 0 0 0 5px rgba(116, 192, 208, 0.63); }
  .atom-meter.warning .atom {
    border-color: #f5a623 !important; }
    .atom-meter.warning .atom .electron {
      background: #f5a623;
      box-shadow: 0 0 0 5px rgba(200, 144, 101, 0.63); }
  .atom-meter.negative .atom {
    border-color: #c52626 !important; }
    .atom-meter.negative .atom .electron {
      background: #c52626;
      box-shadow: 0 0 0 5px rgba(197, 38, 38, 0.63); }
  .atom-meter.success .atom {
    border-color: #208a19 !important; }
    .atom-meter.success .atom .electron {
      background: #208a19;
      box-shadow: 0 0 0 5px rgba(32, 138, 25, 0.63); }

/******************* CUSTOM ATOM METER ********************/
/******************* CUSTOM AVATAR ********************/
img.avatar,
.ui.avatar.image {
  border: 2px solid #74c0d0;
  border-radius: 50%;
  width: unset;
  height: unset; }
  img.avatar.tiny,
  .ui.avatar.image.tiny {
    width: 27px;
    height: 27px; }
  img.avatar.big,
  .ui.avatar.image.big {
    width: 68px;
    height: 68px; }

/******************* CUSTOM AVATAR ********************/
/******************* CUSTOM TABS ********************/
.ui.secondary.pointing.menu .active.item {
  border-color: #74c0d0;
  color: #74c0d0; }

.ui.secondary.pointing.menu .item {
  color: #999999; }
  .ui.secondary.pointing.menu .item:hover, .ui.secondary.pointing.menu .item:focus {
    border-color: #74c0d0 !important;
    color: #74c0d0 !important; }

/******************* CUSTOM TABS ********************/
/******************* CUSTOM TABLE ********************/
table.skejule {
  min-width: 900px;
  border: 0px;
  color: #999999;
  letter-spacing: 0.3px;
  font-size: 14px; }
  table.skejule .name,
  table.skejule .last-name,
  table.skejule th {
    color: #3765b3; }
  table.skejule .email {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  table.skejule .files img {
    width: 26px;
    height: 26px; }
  table.skejule thead tr {
    /*								box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.05);*/ }
    table.skejule thead tr th {
      background: #ffffff;
      height: 37px;
      border-left: 0px;
      border-bottom: 0px;
      padding: 7px 0.78571429em;
      /*									box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);*/ }
      table.skejule thead tr th.descending:after {
        content: "❯";
        transform: rotate(90deg);
        transform-origin: center;
        color: #9e9d9d;
        position: relative;
        left: 20px;
        top: 5px; }
      table.skejule thead tr th.ascending:after {
        content: "❯";
        transform: rotate(-90deg);
        transform-origin: center;
        color: #9e9d9d;
        position: relative;
        left: 7px;
        top: 5px; }
      table.skejule thead tr th:first-child .checkbox {
        margin-top: 3px; }
  table.skejule .skejule-scroll {
    height: auto; }
  @media (min-width: 768px) {
    table.skejule .skejule-scroll {
      height: calc(100vh - 350px); } }
  table.skejule tbody {
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05); }
    table.skejule tbody tr {
      min-height: 60px;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05); }
      table.skejule tbody tr:nth-child(n) {
        background-color: #f9f9f9; }
      table.skejule tbody tr:nth-child(2n) {
        background-color: #ffffff; }
      table.skejule tbody tr.active {
        background: linear-gradient(-135deg, rgba(35, 188, 186, 0.05) 0%, rgba(69, 174, 233, 0.05) 100%) !important; }
      table.skejule tbody tr td {
        min-height: 60px;
        padding: 18px 0.78571429em 14px;
        border-top: 0px; }
        table.skejule tbody tr td.name {
          position: relative; }
          table.skejule tbody tr td.name .viewprofile {
            position: absolute;
            bottom: 0px;
            text-transform: lowercase;
            opacity: 0.75;
            font-weight: 100;
            font-size: 11px;
            color: #74bddb;
            letter-spacing: 0.3px;
            display: none; }
      table.skejule tbody tr:hover td.name .viewprofile, table.skejule tbody tr.active td.name .viewprofile {
        display: block; }
  table.skejule.fixed-header {
    width: 100%;
    background-color: #ffffff; }
    table.skejule.fixed-header tbody {
      width: 100%; }
    table.skejule.fixed-header thead,
    table.skejule.fixed-header thead tr,
    table.skejule.fixed-header tbody,
    table.skejule.fixed-header td,
    table.skejule.fixed-header th {
      display: block; }
    table.skejule.fixed-header tbody td {
      float: left; }
    table.skejule.fixed-header thead {
      padding-right: 5px; }
      table.skejule.fixed-header thead tr th {
        float: left; }
  table.skejule:not(.unstackable) tbody,
  table.skejule:not(.unstackable) tr,
  table.skejule:not(.unstackable) tr > td,
  table.skejule:not(.unstackable) tr > th {
    width: blabla !important;
    display: blabla !important; }

/******************* CUSTOM TABLE ********************/
/******************* CUSTOM INFO ELEMENTS ********************/
.info-elements .info-element {
  margin-bottom: 5px;
  hyphens: auto; }
  .info-elements .info-element .label {
    font-weight: bold;
    color: #3765b3; }
    .info-elements .info-element .label:after {
      content: ": "; }
  .info-elements .info-element .item {
    font-weight: bold;
    color: #3765b3; }
    .info-elements .info-element .item:before {
      content: "- "; }
  .info-elements .info-element .selected-file {
    color: #3765b3;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px; }

/******************* CUSTOM INFO ELEMENTS ********************/
/************* WEBSITE ***********/
html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll; }

body.website {
  /************* TEXT COLORING ***********/
  overflow-x: visible !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #677897 !important;
  line-height: 30px !important; }
  body.website .text-teal {
    color: #74c0d0 !important;
    border-color: #74c0d0 !important; }
  body.website .text-white {
    color: #ffffff !important;
    border-color: #ffffff !important; }
  body.website h1 {
    font-size: 44px;
    color: #ffffff;
    letter-spacing: -1.6px;
    line-height: 48px; }
    body.website h1.website {
      font-size: 36px;
      margin: 0.67em 0; }
  body.website h2 {
    color: #677897;
    letter-spacing: -1.6px;
    line-height: 43px; }
  body.website h1.website,
  body.website h2.website,
  body.website h3.website,
  body.website h4.website,
  body.website h5.website,
  body.website h6.website {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1 !important;
    margin-top: 20px;
    margin-bottom: 10px; }
  body.website button {
    border: 1px solid #ffffff;
    border-radius: 20px;
    background: transparent;
    padding: 2px 30px;
    color: #ffffff; }
    body.website button.website {
      border: 1px solid #ffffff;
      border-radius: 20px !important;
      padding: 2px 30px !important;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit; }
  body.website button.filled {
    border: 1px solid #ffffff;
    border-radius: 20px;
    background: #ffffff;
    padding: 2px 30px;
    color: #74c0d0 !important; }
  body.website .row {
    margin-right: 0;
    margin-left: 0; }
  body.website section.presentation {
    background-image: linear-gradient(-135deg, #23bcba 0%, #45e994 100%); }
    body.website section.presentation .content-website.content1 .subtitle {
      font-size: 16px; }
  body.website section.presentation .computer {
    background: transparent url("./images/computer.png") no-repeat right center;
    background-size: contain;
    width: 100%;
    min-height: 500px; }
  body.website section.presentation .tealtile {
    width: 3000px;
    height: 2000px;
    position: absolute;
    top: -2000px;
    left: -800px;
    background: #6ac7da;
    background: -webkit-linear-gradient(#6ac7da 0%, #56ceaa 100vh);
    background: -o-linear-gradient(#6ac7da 0%, #56ceaa 100vh);
    background: -moz-linear-gradient(#6ac7da 0%, #56ceaa 100vh);
    background: linear-gradient(#6ac7da 0%, #56ceaa 100vh);
    transform: rotate(75deg);
    -moz-transform: rotate(75deg);
    -webkit-transform: rotate(75deg);
    border-radius: 50px;
    box-shadow: 0px 0px 35px #bbbbbb; }
  body.website section .content-website {
    padding: 20px 50px 50px !important; }
  body.website section.presentation .buttons button {
    width: 100%; }
  body.website section.presentation .buttons .more {
    margin-bottom: 30px; }
  body.website section.presentation .buttons .touch {
    margin-left: 0px; }
  body.website section.what {
    background: transparent url("./images/cereal.png") no-repeat center top;
    background-size: 160% auto; }
    body.website section.what .content-website.content2 .subtitle {
      font-size: 16px; }
  body.website section.what .ipad {
    background: transparent url("./images/ipad.png") no-repeat -200% center;
    background-size: auto 100%;
    width: 100%;
    min-height: 300px;
    margin-bottom: 0px;
    margin-top: -100px; }
  body.website section.features {
    /** Grid Features Container **/ }
    body.website section.features .subtitle {
      font-size: 16px; }
    body.website section.features .more-information {
      cursor: pointer;
      font-size: 12px;
      font-weight: 500; }
      body.website section.features .more-information:hover {
        text-decoration: underline; }
    body.website section.features .gridcontainer {
      margin: 3rem 0 0 0; }
      body.website section.features .gridcontainer .tile-card {
        border: 1px solid #74c0d0;
        outline: 1px solid #74c0d0;
        height: 350px;
        perspective: 1000px;
        text-align: center;
        /* This container is needed to position the front and back side */
        /* Style the front side */ }
        @media (min-width: 768px) {
          body.website section.features .gridcontainer .tile-card {
            height: 320px; } }
        @media (min-width: 992px) {
          body.website section.features .gridcontainer .tile-card {
            height: 300px; } }
        body.website section.features .gridcontainer .tile-card .tile-card-inner {
          position: relative;
          width: 100%;
          height: 100%;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          /* Position the front and back side */ }
          body.website section.features .gridcontainer .tile-card .tile-card-inner .tile-card-front,
          body.website section.features .gridcontainer .tile-card .tile-card-inner .tile-card-back {
            backface-visibility: hidden;
            position: absolute;
            width: 100%; }
            body.website section.features .gridcontainer .tile-card .tile-card-inner .tile-card-front .featuretitle,
            body.website section.features .gridcontainer .tile-card .tile-card-inner .tile-card-back .featuretitle {
              font-size: 22px;
              font-weight: 600;
              color: #68c7d9;
              display: block; }
        body.website section.features .gridcontainer .tile-card:hover .tile-card-inner {
          transform: rotateY(180deg); }
        body.website section.features .gridcontainer .tile-card .tile-card-front {
          padding: 4rem; }
          body.website section.features .gridcontainer .tile-card .tile-card-front .img-responsive img {
            max-width: 275px !important; }
          body.website section.features .gridcontainer .tile-card .tile-card-front .featuretitle {
            margin-top: 2.5rem; }
        body.website section.features .gridcontainer .tile-card .tile-card-back {
          align-items: center;
          background-color: #f8f8f8;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 2rem;
          transform: rotateY(180deg); }
          body.website section.features .gridcontainer .tile-card .tile-card-back .featuretitle {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem; }
          body.website section.features .gridcontainer .tile-card .tile-card-back .featuredescription {
            font-size: 16px;
            line-height: 1.6;
            margin-top: auto;
            margin-bottom: auto;
            overflow: auto;
            padding-right: 1rem;
            text-align: justify; }
  body.website section.features .circles {
    background: transparent url("./images/circles_animated.svg") no-repeat center center;
    background-size: auto 100%; }
  body.website section.features .circles img {
    width: 100% !important; }
  body.website section.features .circles #iphone-carousel .blackbg {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 5px);
    height: calc(100% - 10px);
    background: #000000;
    overflow: hidden;
    border-radius: 10%; }
  body.website section.features .circles #iphone-carousel .slick-list {
    border-radius: 10%; }
  body.website section.features .circles #iphone-carousel .carousel-indicators {
    display: none; }
  body.website section.features .circles #iphone-carousel .carousel-inner {
    padding: 0px;
    border-radius: 30px; }
  body.website section.features .circles #iphone-carousel .carousel-inner > .item {
    overflow: hidden; }
  body.website section.features .circles #iphone-carousel .carousel-inner > .item img {
    border-radius: 25px;
    overflow: hidden; }
  body.website section.features .circles #iphone-carousel .carousel-control {
    background: transparent; }
  body.website section.features .circles #iphone-carousel .carousel-control.left {
    left: -70px !important; }
  body.website section.features .circles #iphone-carousel .carousel-control.right {
    right: -70px !important; }
  body.website section.features .circles #iphone-carousel .frame {
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px); }
  body.website section.features .featurelist {
    /*	background: linear-gradient(to right, rgba(116, 192, 208, 0.6) 0%,rgba(116, 192, 208, 0) 100%);*/
    text-align: left;
    margin-left: -3px; }
  body.website section.features .featurelist.featurelist1 {
    margin-top: 88px; }
  body.website section.features .featurelist.featurelist2 {
    margin-top: 0px; }
  body.website section.features .featurelist .feature {
    line-height: 20px;
    margin-bottom: 50px;
    text-align: center;
    padding: 0 30px; }
  body.website section.features .featurelist .feature .icon {
    width: 100px;
    height: 100px;
    margin: 0 auto 30px; }
  body.website section.testimonials {
    background-color: #56ceaa;
    /** Testimonial Container **/ }
    body.website section.testimonials .testimonialcontainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 40px;
      grid-auto-flow: row;
      grid-template-areas: "."; }
      @media (min-width: 768px) {
        body.website section.testimonials .testimonialcontainer {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". ."; } }
      @media (min-width: 992px) {
        body.website section.testimonials .testimonialcontainer {
          grid-template-columns: 0.5fr 1fr 1fr;
          grid-template-areas: ". . ."; } }
      body.website section.testimonials .testimonialcontainer .header img {
        width: 70px; }
      body.website section.testimonials .testimonialcontainer .header h1 {
        color: #fff; }
      body.website section.testimonials .testimonialcontainer .schoolwrapper {
        align-items: center;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        width: 100%; }
        body.website section.testimonials .testimonialcontainer .schoolwrapper img {
          max-width: 100%;
          max-height: 100%; }
        body.website section.testimonials .testimonialcontainer .schoolwrapper .schooltestimonial,
        body.website section.testimonials .testimonialcontainer .schoolwrapper .schoolname {
          padding: 3rem;
          line-height: 1.6;
          font-size: 16px; }
        body.website section.testimonials .testimonialcontainer .schoolwrapper .schoolname {
          margin-top: auto;
          font-weight: 600; }
  body.website section.process {
    /** Process Container **/ }
    body.website section.process .processcontainer {
      margin: 3rem 0 0 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 40px;
      grid-auto-flow: row;
      grid-template-areas: "."; }
      @media (min-width: 768px) {
        body.website section.process .processcontainer {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". ."; } }
      body.website section.process .processcontainer .processwrapper {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center; }
        body.website section.process .processcontainer .processwrapper img {
          max-width: 100%;
          max-height: 100%;
          margin: 20px; }
  body.website section.testimonials,
  body.website section.process,
  body.website section.about,
  body.website section.company,
  body.website section.whoweare,
  body.website section.ourmission {
    padding: 8rem 4rem; }
  body.website section.background {
    padding: 20rem 0; }
    body.website section.background.about-us {
      background: transparent url("./images/fondo-about-us.jpg") no-repeat center center;
      background-size: cover; }
  body.website section.company,
  body.website section.ourmission {
    background-color: #fbfbfb;
    /** Company Container **/
    /** Team Container **/ }
    body.website section.company .companycontainer,
    body.website section.ourmission .companycontainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 67px;
      grid-auto-flow: row;
      grid-template-areas: ".";
      align-items: center; }
      @media (min-width: 768px) {
        body.website section.company .companycontainer,
        body.website section.ourmission .companycontainer {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ". ."; } }
      body.website section.company .companycontainer .logo,
      body.website section.ourmission .companycontainer .logo {
        text-align: center; }
        body.website section.company .companycontainer .logo img,
        body.website section.ourmission .companycontainer .logo img {
          width: 300px; }
      body.website section.company .companycontainer .body,
      body.website section.ourmission .companycontainer .body {
        margin-top: 20px;
        text-align: center; }
        @media (min-width: 768px) {
          body.website section.company .companycontainer .body,
          body.website section.ourmission .companycontainer .body {
            margin-top: 0;
            text-align: left; } }
        body.website section.company .companycontainer .body h4,
        body.website section.ourmission .companycontainer .body h4 {
          font-weight: 600;
          color: #74c0d0; }
        body.website section.company .companycontainer .body span,
        body.website section.ourmission .companycontainer .body span {
          line-height: 1.6;
          font-size: 18px; }
    body.website section.company .missioncontainer,
    body.website section.ourmission .missioncontainer {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      align-items: center; }
      body.website section.company .missioncontainer .body,
      body.website section.ourmission .missioncontainer .body {
        max-width: 500px; }
        body.website section.company .missioncontainer .body h4,
        body.website section.ourmission .missioncontainer .body h4 {
          color: #74c0d0;
          font-weight: 600; }
        body.website section.company .missioncontainer .body span,
        body.website section.ourmission .missioncontainer .body span {
          line-height: 1.6;
          font-size: 18px; }
  body.website section.whoweare {
    background-color: #56ceaa;
    /** Team Container **/ }
    body.website section.whoweare .teamcontainer {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      align-items: center; }
      body.website section.whoweare .teamcontainer .body {
        max-width: 500px;
        color: #fff; }
        body.website section.whoweare .teamcontainer .body h4 {
          font-weight: 600; }
        body.website section.whoweare .teamcontainer .body span {
          line-height: 1.6;
          font-size: 18px; }
  body.website section.newsletter {
    border-top: 1px solid #74c0d0;
    border-bottom: 1px solid #74c0d0;
    color: #989a9c;
    padding: 20px 50px 50px !important;
    margin-top: 5px; }
  body.website section.newsletter .buttons {
    padding: 17px 0px; }
  body.website section.newsletter .buttons button {
    width: 100%; }
  body.website section.mobile-stores {
    background-color: #56ceaa;
    color: #fff; }
  body.website section.prefooter {
    margin-top: 0px;
    margin-bottom: 50px; }
  body.website section.prefooter .logo {
    padding: 30px 0px 0px;
    text-align: center; }
  body.website section.prefooter .logo img {
    height: 50px; }
  body.website section.prefooter .followus {
    text-align: center;
    margin-top: 30px; }
  body.website section.prefooter .social-networks {
    margin-top: 10px;
    display: inline-flex;
    position: absolute;
    left: 50%;
    margin-left: -85px; }
  body.website section.prefooter .social-networks img {
    height: 30px; }
  body.website section.prefooter .sitemap {
    border-top: 4px solid #74c0d0;
    display: inline-block;
    margin-top: 40px; }
  body.website section.prefooter .sitemap ul {
    list-style: none;
    padding: 0px 10px; }
  body.website section.prefooter .sitemap ul li a {
    color: #989a9c; }
  body.website section.breadcrumbs {
    margin: 20px 0px 20px -10px; }
  body.website section.breadcrumbs .crumb,
  body.website section.breadcrumbs .crumb-mobile {
    display: inline-block;
    font-size: 18px;
    color: #9b9b9b;
    margin-right: 3px; }
    body.website section.breadcrumbs .crumb a,
    body.website section.breadcrumbs .crumb-mobile a {
      color: #9b9b9b; }
  body.website section.breadcrumbs .crumb::before {
    content: ">";
    margin: 0 5px; }
  body.website section.breadcrumbs .crumb-mobile::before {
    content: "<";
    margin: 0 5px; }
  body.website section.breadcrumbs .crumb:first-child::before {
    content: "" !important; }
  body.website section.contact,
  body.website section.input-form {
    background: transparent url("./images/cereal.png") no-repeat center center;
    background-size: 75% auto;
    margin-bottom: 0px; }
  body.website section.input-form .message {
    font-size: 14px; }
  body.website section.contact .form {
    padding: 0px 50px; }
  body.website section.input-form .form form {
    padding: 0px 50px; }
  body.website section.contact .form form {
    margin-top: 0px; }
  body.website section.contact .form input {
    border: 1px solid #dcdcdc;
    border-radius: 100px;
    padding: 10px 20px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    height: unset; }
  body.website section.contact .form form input.info,
  body.website section.contact .form form .form-group.info input {
    border-color: #74c0d0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }
  body.website section.contact .form form input.warning,
  body.website section.contact .form form .form-group.warning input {
    border-color: #f5a623 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 166, 35, 0.6); }
  body.website section.contact .form form input.negative,
  body.website section.contact .form form .form-group.negative input {
    border-color: #c52626 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(197, 38, 38, 0.6); }
  body.website section.contact .form form input.success,
  body.website section.contact .form form .form-group.success input {
    border-color: #208a19 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(32, 138, 25, 0.6); }
  body.website section.contact .form form textarea {
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    padding: 15px 20px;
    resize: none;
    width: 100%; }
  body.website section.contact .form form button[type="submit"] {
    background: #FFFFFFF !important;
    background-image: none !important;
    border-radius: 100px;
    padding: 10px 20px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    height: unset;
    margin-bottom: 15px; }
  body.website section.contact .form form button[type="submit"]:hover,
  body.website section.contact .form form button[type="submit"]:active,
  body.website section.contact .form form button[type="submit"]:focus {
    background: #74c0d0 !important;
    background-image: none !important;
    color: #ffffff !important;
    text-shadow: none !important; }
  body.website section.contact .form form .form-control:focus {
    border-color: #74c0d0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }
  body.website section.contact .message,
  body.website section.input-form .message {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.33px;
    padding: 0px 20px; }
  body.website section.contact .message.info {
    color: #74c0d0; }
  body.website section.contact .message.warning {
    color: #f5a623; }
  body.website section.contact .message.negative {
    color: #c52626; }
  body.website section.contact .message.success {
    color: #208a19; }
  body.website section.contact .btn-default[disabled] {
    opacity: 1;
    color: #ffffff;
    border-color: #e0e0e0;
    text-shadow: 0px 0px 0px; }
  body.website section.contact .subtitle,
  body.website section.input-form .subtitle {
    font-size: 18px;
    color: #a9aaac;
    letter-spacing: 0.3px;
    line-height: 22px; }
  body.website section.contact .url .fa {
    display: none; }
  body.website section.contact .url.success .fa {
    display: inline-block;
    color: #208a19; }
  body.website section.contact .url .label.mainurl {
    color: #3765b3;
    font-size: 14px;
    padding: 10px 0px;
    line-height: 45px; }
  body.website .barseparator {
    background: #62c9c8 !important;
    height: 7px; }
  body.website section.about h2.website {
    line-height: 1.3 !important;
    margin-bottom: 15px; }
  body.website section.about .philosophy {
    padding: 20px 50px 50px;
    margin-top: 0px; }
  body.website section.about .company {
    padding: 20px 50px 50px; }
  body.website section.about.aboutus1 {
    background: transparent url("./images/cereal.png") no-repeat center center;
    background-size: 100% auto; }
  body.website section.about.aboutus2 {
    margin-top: 50px; }
  body.website section.about .panels {
    position: relative;
    width: 100%; }
  body.website section.about.aboutus2 .panels {
    margin-top: 0px;
    margin-left: 0px; }
  body.website section.about .panels .panel1,
  body.website section.about .panels .panel2,
  body.website section.about .panels .panel3 {
    background: #74c0d0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.49);
    border-radius: 7px;
    position: absolute; }
  body.website section.about .panels .panel1 {
    width: 100%;
    height: 60%;
    z-index: -1;
    left: 30px;
    top: 30px; }
    @media (max-width: 1199px) and (min-width: 992px) {
      body.website section.about .panels .panel1 {
        height: 50%; } }
    @media (max-width: 992px) and (min-width: 768px) {
      body.website section.about .panels .panel1 {
        height: 37%; } }
  body.website section.about .panels .panel2 {
    width: 50%;
    height: 50%;
    z-index: 2;
    bottom: 0px;
    left: 50%;
    margin-left: -120px; }
  body.website section.about .panels .panel3 {
    width: 65%;
    height: 65%;
    z-index: 3;
    left: 0px;
    top: 50%;
    margin-top: -125px; }
  body.website section.about .panels img {
    position: relative; }
  @media (min-width: 768px) {
    body.website section.presentation {
      background: none; }
    body.website section .content-website {
      padding: 50px !important;
      padding-right: 5px !important; }
      body.website section .content-website .content1 .subtitle {
        font-size: 16px; }
    body.website section.presentation .content-website.content1 .buttons button {
      width: auto; }
    body.website section.presentation .content-website.content1 .buttons .more {
      margin-bottom: 0px; }
    body.website section.presentation .content-website.content1 .buttons .touch {
      margin-left: 20px; }
    body.website section.what {
      background: transparent url("./images/cereal.png") no-repeat right center;
      background-size: 70% auto; }
    body.website section.what .ipad {
      background: transparent url("./images/ipad.png") no-repeat 90% center;
      background-size: auto 100%;
      width: 100%;
      min-height: 800px;
      margin-top: 200px; }
    body.website section.features .circles img {
      width: 100% !important; }
    body.website section.features .featurelist.featurelist1,
    body.website section.features .featurelist.featurelist2 {
      margin-top: 88px; }
    body.website section.newsletter {
      padding: 50px 15px !important; }
    body.website section.newsletter .buttons button {
      width: auto; }
    body.website section.about .panels {
      min-height: 400px; }
    body.website section.about .philosophy {
      padding: 50px; }
    body.website section.about .company {
      padding: 50px; }
    body.website section.about.aboutus2 {
      margin-top: 0px; }
    body.website section.about.aboutus2 .panels {
      margin-top: 50px;
      margin-left: 50px; }
    body.website section.about .panels img {
      position: absolute; }
    body.website section.prefooter {
      margin-top: 100px;
      margin-bottom: 100px; }
    body.website section.prefooter .logo {
      padding: 43px 0px;
      text-align: left; }
    body.website section.prefooter .followus {
      text-align: right;
      margin-top: 0px; }
    body.website section.prefooter .social-networks {
      margin-top: 23px;
      display: block;
      position: relative;
      left: unset;
      margin-left: 0px; }
    body.website section.contact {
      margin-bottom: 100px; }
    body.website section.contact .form {
      padding: 50px; }
    body.website section.contact .form form {
      margin-top: 130px; }
    body.website section.input-form .form form {
      padding: 0px; } }

.website-home,
.website-breadcrumbs,
.website-signin,
.website-contact,
.website-about,
.website-recovery-pwd,
.website-reset-pwd,
.website-footer {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .website-home button,
  .website-breadcrumbs button,
  .website-signin button,
  .website-contact button,
  .website-about button,
  .website-recovery-pwd button,
  .website-reset-pwd button,
  .website-footer button {
    margin: 0;
    font: inherit;
    color: inherit; }
  .website-home h4,
  .website-breadcrumbs h4,
  .website-signin h4,
  .website-contact h4,
  .website-about h4,
  .website-recovery-pwd h4,
  .website-reset-pwd h4,
  .website-footer h4 {
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 10px;
    margin-bottom: 10px; }

footer {
  background: #e6effa;
  margin-top: 30px; }
  @media (min-width: 768px) {
    footer {
      margin-top: 0px; } }

::-webkit-scrollbar {
  width: 5px !important;
  background: #dcdcdc !important; }

::-webkit-scrollbar-track {
  background: #dcdcdc !important; }

::-webkit-scrollbar-thumb {
  background: #74c0d0 !important; }
