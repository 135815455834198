.navbar-default {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: auto;
  color: #74c0d0;
  background: #ffffff !important;
  margin-bottom: 0px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075); }

.navbar-default .navbar-brand {
  padding: 0px;
  width: 120px;
  position: absolute;
  left: 33%;
  margin-left: -25px; }
  .navbar-default .navbar-brand img.logo {
    width: 150px !important;
    max-width: initial !important; }

.navbar-default .navbar-collapse {
  padding: 35px 0px 15px;
  margin-top: 25px;
  overflow: hidden;
  border-top: 0px; }

.navbar-default .navbar-nav li a {
  color: #74c0d0 !important;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 15px; }

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > li:hover > a,
.navbar-default .navbar-nav > li:focus > a {
  background: #ffffff !important;
  outline: 0px !important;
  text-decoration: underline;
  box-shadow: 0px 0px 0px !important; }

.navbar-default button.signin {
  margin-top: 7px;
  text-transform: uppercase; }

.navbar-default .navbar-header button.signin {
  margin-top: 0px;
  border: 0px;
  padding: 8px 10px; }
  .navbar-default .navbar-header button.signin:focus {
    outline: 0; }

.navbar-default .navbar-header button.signin img {
  width: 22px;
  height: 22px; }

.navbar-default .navbar-toggle {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  border: 0px;
  padding: 9px 10px;
  border-radius: 4px !important; }

.navbar-default .navbar-toggle:hover {
  background: #74c0d0 !important; }

.navbar-default .navbar-toggle .icon-bar {
  background-color: #74c0d0 !important; }

.navbar-default .navbar-toggle:hover .icon-bar {
  background-color: #ffffff !important; }

@media (min-width: 768px) {
  .navbar-default {
    margin-bottom: 20px !important; }
  .navbar-default {
    height: 128px; }
  .navbar-default .navbar-brand {
    padding: 30px 0px;
    width: 100%;
    position: relative;
    left: unset;
    margin-left: 0px; }
  .navbar-default .navbar-collapse {
    margin-top: 0px; } }
