section.contact form .react-phone-number-input.row-telephone {
  margin-right: 0px; }
  section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row {
    padding-left: 10px; }
    section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row .react-phone-number-input__country .react-phone-number-input__icon {
      width: 25px;
      height: 25px;
      border: none; }
    section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row .react-phone-number-input__country .react-phone-number-input__icon-image {
      position: relative;
      bottom: 5px; }
    section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row .react-phone-number-input__country .react-phone-number-input__icon--international {
      width: calc(1.5em + 2px);
      height: calc(1.5em + 2px); }
    section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row input.react-phone-number-input__input {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
      section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row input.react-phone-number-input__input:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
      section.contact form .react-phone-number-input.row-telephone .react-phone-number-input__row input.react-phone-number-input__input::-webkit-input-placeholder {
        color: #999; }

section.contact form input:placeholder-shown,
section.contact form textarea:placeholder-shown {
  background: #fff !important; }

section.contact form button[type="submit"] {
  background: #fff !important;
  background-image: none !important;
  border: 1px solid #74c0d0;
  border-radius: 100px;
  padding: 10px 20px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  height: unset;
  color: #74c0d0;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle; }
  section.contact form button[type="submit"]:hover, section.contact form button[type="submit"]:active, section.contact form button[type="submit"]:focus {
    background: #74c0d0 !important;
    background-image: none !important;
    color: #ffffff !important;
    text-shadow: none !important;
    outline: 0; }
