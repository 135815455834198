.ui.modal.modal-activation-account > .header {
  background-color: transparent; }

.ui.modal.modal-activation-account > .content {
  background-color: transparent; }
  .ui.modal.modal-activation-account > .content .activation-account-success .text-message,
  .ui.modal.modal-activation-account > .content .activation-account-error .text-message {
    font-weight: bolder;
    line-height: 2; }
    .ui.modal.modal-activation-account > .content .activation-account-success .text-message.description,
    .ui.modal.modal-activation-account > .content .activation-account-error .text-message.description {
      color: #ccc;
      font-size: 18px; }
