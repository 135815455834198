.modal-create-company.signin .ui.labeled {
  font-size: 14px;
  line-height: 1.42857143; }
  .modal-create-company.signin .ui.labeled.input.skejule > .label {
    background: rgba(255, 255, 255, 0);
    color: #3765b3;
    padding-left: 5px;
    font-weight: bolder;
    margin-right: 2em;
    display: none; }
    @media (min-width: 768px) {
      .modal-create-company.signin .ui.labeled.input.skejule > .label {
        display: inherit; } }
  .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection {
    border-radius: 18px;
    border-top-right-radius: 0.28571429rem !important;
    border-bottom-right-radius: 0.28571429rem !important;
    border-right-color: #dcdcdc !important;
    padding-right: 10px !important;
    text-align: right; }
    .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection.active, .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection:hover {
      border-color: #74c0d0 !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }
    .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection.empty {
      background: #f1f2f2; }
    .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection .text {
      text-align: right;
      color: #999;
      font-size: 14px; }
    .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection .default.text {
      opacity: 0.5; }
    .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection .menu.visible .icon.trash {
      opacity: unset;
      font-size: 12px; }
      .modal-create-company.signin .ui.labeled.input.skejule .ui.dropdown.selection .menu.visible .icon.trash:hover:before {
        border: 1px solid;
        border-radius: 5px;
        padding: 2px; }
  .modal-create-company.signin .ui.labeled.input.skejule input {
    border-top-right-radius: 0.28571429rem !important;
    border-bottom-right-radius: 0.28571429rem !important;
    border-right-color: #dcdcdc !important;
    padding-right: 10px !important;
    text-align: right; }
    .modal-create-company.signin .ui.labeled.input.skejule input:focus {
      border-color: #74c0d0 !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }

.modal-create-company.signin button {
  font-size: 14px;
  line-height: 1.42857143; }

.modal-create-company.signin .ui.form input[type="email"],
.modal-create-company.signin .ui.form input[type="password"] {
  font-size: 14px;
  line-height: 1.42857143;
  background: #fff; }
  .modal-create-company.signin .ui.form input[type="email"]:focus,
  .modal-create-company.signin .ui.form input[type="password"]:focus {
    border: 1px solid #74c0d0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }

.modal-create-company.signin .ui.form div[field="accountSelected"] .ui.selection.dropdown {
  border-radius: 18px;
  border: 1px solid #74c0d0;
  color: #74c0d0; }
  .modal-create-company.signin .ui.form div[field="accountSelected"] .ui.selection.dropdown .menu > .item {
    line-height: 2em;
    font-size: 1em; }

.modal-create-company.signin .ui.form .warning.message {
  display: inherit;
  font-size: 1.325rem; }

.modal-create-company.signin .ui.form .field.success input[type="email"],
.modal-create-company.signin .ui.form .field.success input[type="password"],
.modal-create-company.signin .ui.form .fields.success .field input[type="email"],
.modal-create-company.signin .ui.form .fields.success .field input[type="password"] {
  border: 1px solid #208a19 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(32, 138, 25, 0.6); }
  .modal-create-company.signin .ui.form .field.success input[type="email"]:focus,
  .modal-create-company.signin .ui.form .field.success input[type="password"]:focus,
  .modal-create-company.signin .ui.form .fields.success .field input[type="email"]:focus,
  .modal-create-company.signin .ui.form .fields.success .field input[type="password"]:focus {
    border: 1px solid #208a19 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(32, 138, 25, 0.6); }

.modal-create-company.signin .ui.form .field.warning input[type="email"],
.modal-create-company.signin .ui.form .field.warning input[type="password"],
.modal-create-company.signin .ui.form .fields.warning .field input[type="email"],
.modal-create-company.signin .ui.form .fields.warning .field input[type="password"] {
  border: 1px solid #f5a623 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 166, 35, 0.6); }
  .modal-create-company.signin .ui.form .field.warning input[type="email"]:focus,
  .modal-create-company.signin .ui.form .field.warning input[type="password"]:focus,
  .modal-create-company.signin .ui.form .fields.warning .field input[type="email"]:focus,
  .modal-create-company.signin .ui.form .fields.warning .field input[type="password"]:focus {
    border: 1px solid #f5a623 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 166, 35, 0.6); }

.modal-create-company.signin .ui.form .field .input img,
.modal-create-company.signin .ui.form .fields .field .input img {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  width: 1em;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

.modal-create-company.signin .ui.button:disabled,
.modal-create-company.signin .ui.disabled.button {
  opacity: 0.4 !important;
  background: #999; }

.ui.modals.dimmer .subscription-link h4 {
  font-weight: 700;
  line-height: 2.5rem; }

.ui.modals.dimmer .subscription-link .text-justify {
  text-align: justify; }

.ui.modals.dimmer .subscription-link button {
  border: 1px solid #74c0d0;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  line-height: 1.42857143;
  padding: 10px 20px;
  margin-bottom: 5px;
  font-size: 14px; }
  .ui.modals.dimmer .subscription-link button:hover, .ui.modals.dimmer .subscription-link button:active, .ui.modals.dimmer .subscription-link button:focus {
    background: #fff; }
    .ui.modals.dimmer .subscription-link button:hover.skejule, .ui.modals.dimmer .subscription-link button:active.skejule, .ui.modals.dimmer .subscription-link button:focus.skejule {
      color: #74c0d0;
      text-shadow: none;
      outline: 0; }
