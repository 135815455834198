section.legal {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 20px !important; }
  section.legal h1,
  section.legal h2,
  section.legal h3,
  section.legal h4,
  section.legal h5,
  section.legal h6 {
    color: #74c0d0;
    text-transform: unset; }
  section.legal .legal-tabs .menu .item {
    font-size: 14px; }
  section.legal .content.skejule-scroll {
    height: 50vh;
    /*70vh*/
    border-top: 0; }
    section.legal .content.skejule-scroll .ui.bulleted.list {
      padding-left: 1.25rem; }
