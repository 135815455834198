html#user-portfolio {
  width: 100%;
  height: 100%;
  overflow-x: unset;
  overflow-y: unset; }

#main {
  /* .body */ }
  #main.userportfolio {
    /* .userprofile */ }
    #main.userportfolio .body {
      background: #ffffff;
      font-size: 14px;
      padding-top: 10px;
      /* .main */ }
      #main.userportfolio .body.visible .row {
        margin-left: 0;
        margin-right: 0; }
      #main.userportfolio .body > .ui.grid {
        margin-top: 0px; }
        @media (min-width: 992px) {
          #main.userportfolio .body > .ui.grid {
            margin-top: -1rem; } }
      #main.userportfolio .body .main {
        padding: 5px;
        padding-bottom: 10px; }
        #main.userportfolio .body .main h1,
        #main.userportfolio .body .main h4 {
          font-family: "Montserrat", sans-serif;
          color: #77c0cf;
          font-size: 2em;
          line-height: 1.28571429em;
          margin: calc(2rem - 0.14285714em) 0 1rem;
          font-weight: 700; }
        #main.userportfolio .body .main h4 {
          font-size: 1.07142857em; }
        #main.userportfolio .body .main .uppercase {
          text-transform: uppercase !important; }
        #main.userportfolio .body .main .content-header.profile .user-info {
          padding: 1rem; }
          @media (max-width: 767px) {
            #main.userportfolio .body .main .content-header.profile .user-info {
              text-align: center; } }
          #main.userportfolio .body .main .content-header.profile .user-info .ui.grid > .row {
            padding: 0; }
          #main.userportfolio .body .main .content-header.profile .user-info .ui.grid .row > .column {
            padding: 1rem; }
          #main.userportfolio .body .main .content-header.profile .user-info .row-top .ui.avatar.image.big {
            border-width: 4px;
            height: 105px;
            width: 105px; }
            @media (min-width: 992px) {
              #main.userportfolio .body .main .content-header.profile .user-info .row-top .ui.avatar.image.big {
                height: 115px;
                width: 115px; } }
          #main.userportfolio .body .main .content-header.profile .user-info .row-top .pt3 {
            padding-top: 3px; }
          #main.userportfolio .body .main .content-header.profile .user-info .row-top .ui.list.contact-info {
            margin: calc(2.5rem - 0.14285714em) 0 1rem; }
            #main.userportfolio .body .main .content-header.profile .user-info .row-top .ui.list.contact-info .item {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden; }
            @media (max-width: 767px) {
              #main.userportfolio .body .main .content-header.profile .user-info .row-top .ui.list.contact-info {
                margin: 0; }
                #main.userportfolio .body .main .content-header.profile .user-info .row-top .ui.list.contact-info .item {
                  display: flex;
                  align-items: center;
                  justify-content: center; } }
          @media (min-width: 768px) {
            #main.userportfolio .body .main .content-header.profile .user-info .row-top .twelve.wide.column div.row {
              padding-left: 20px; }
            #main.userportfolio .body .main .content-header.profile .user-info .row-top svg {
              float: right; } }
          @media (max-width: 769px) {
            #main.userportfolio .body .main .content-header.profile .user-info {
              margin-bottom: 15px; } }
        @media (min-width: 768px) {
          #main.userportfolio .body .main .content-header.profile {
            margin-top: 0px; } }
        #main.userportfolio .body .main .content-body .widgets .ui.grid > .row {
          padding: 0; }
        #main.userportfolio .body .main .content-body .widgets .ui.grid .row > .column {
          padding: 1rem; }
        #main.userportfolio .body .main .content-body .widgets .ui.grid > .row:last-child {
          padding-bottom: 5px; }
        #main.userportfolio .body .main .content-body .widgets .widget {
          border-radius: 5px;
          font-size: 14px;
          /*.widget-body*/ }
          #main.userportfolio .body .main .content-body .widgets .widget .icono {
            font-size: 27px !important;
            vertical-align: middle !important;
            box-shadow: none; }
          #main.userportfolio .body .main .content-body .widgets .widget .widget-header {
            color: #999;
            flex-grow: inherit;
            font-weight: bold;
            padding: 5px 10px; }
            #main.userportfolio .body .main .content-body .widgets .widget .widget-header .title {
              text-transform: uppercase; }
          #main.userportfolio .body .main .content-body .widgets .widget .widget-body {
            padding: 10px 15px; }
            #main.userportfolio .body .main .content-body .widgets .widget .widget-body .icono {
              position: relative;
              bottom: 5px; }
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body .atom-label {
            color: #8c8c8c; }
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body table.table.procedures .right,
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body table.table.diagnoses .right {
            float: unset !important; }
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body table.table.procedures thead th,
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body table.table.diagnoses thead th {
            color: #3765b3; }
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body table.table.procedures tbody,
          #main.userportfolio .body .main .content-body .widgets .widget.case-logs .widget-body table.table.diagnoses tbody {
            color: #999; }
          #main.userportfolio .body .main .content-body .widgets .widget.institutions .ui.avatar.image.small, #main.userportfolio .body .main .content-body .widgets .widget.testimonials .ui.avatar.image.small {
            width: 54px;
            height: 54px; }
            @media only screen and (max-width: 767px) {
              #main.userportfolio .body .main .content-body .widgets .widget.institutions .ui.avatar.image.small, #main.userportfolio .body .main .content-body .widgets .widget.testimonials .ui.avatar.image.small {
                width: 84px !important;
                height: 84px; } }
          #main.userportfolio .body .main .content-body .widgets .widget.testimonials .widget-body .ui.items > .item > .content > .description {
            text-align: justify;
            line-height: 1.6em;
            color: #8c8c8c; }
          #main.userportfolio .body .main .content-body .widgets .widget.experience .widget-body .ui.list.celled > .item:first-child {
            border-top: 0px; }
          #main.userportfolio .body .main .content-body .widgets .widget.experience .widget-body .ui.list .item .content .ui.list > .none:before {
            content: ""; }
          #main.userportfolio .body .main .content-body .widgets .widget.experience .widget-body .ui.list .item .content .header {
            color: rgba(0, 0, 0, 0.7);
            font-weight: 400;
            font-size: 18px; }
          #main.userportfolio .body .main .content-body .widgets .widget.experience .widget-body .ui.list .item .content .description {
            text-align: justify;
            line-height: 1.6em;
            color: #8c8c8c; }
          #main.userportfolio .body .main .content-body .widgets .widget.experience .widget-body .ui.list .item .content .description.grayscale {
            color: #888;
            opacity: 0.7; }
          #main.userportfolio .body .main .content-body .widgets .widget.education .widget-body .ui.list li {
            list-style-type: circle;
            color: #6ac7da; }
            #main.userportfolio .body .main .content-body .widgets .widget.education .widget-body .ui.list li:before {
              content: "";
              position: absolute;
              height: calc(100% - 20px);
              top: 20px;
              left: -15px;
              width: 2px;
              background-color: #e9e9e9;
              margin-left: initial; }
            #main.userportfolio .body .main .content-body .widgets .widget.education .widget-body .ui.list li .content .description {
              line-height: 1.4em;
              color: #8c8c8c;
              margin-bottom: 5px; }
            #main.userportfolio .body .main .content-body .widgets .widget.education .widget-body .ui.list li .content .description.grayscale {
              color: #888;
              opacity: 0.7; }
            #main.userportfolio .body .main .content-body .widgets .widget.education .widget-body .ui.list li .content .header {
              color: rgba(0, 0, 0, 0.7); }
          #main.userportfolio .body .main .content-body .widgets .widget.publications .widget-body .ui.list.celled > .item:first-child, #main.userportfolio .body .main .content-body .widgets .widget.volunteers .widget-body .ui.list.celled > .item:first-child {
            border-top: 0px; }
          #main.userportfolio .body .main .content-body .widgets .widget.publications .widget-body .ui.list .item .content .description, #main.userportfolio .body .main .content-body .widgets .widget.volunteers .widget-body .ui.list .item .content .description {
            line-height: 1.4em;
            color: #8c8c8c;
            margin-bottom: 5px; }
          #main.userportfolio .body .main .content-body .widgets .widget.publications .widget-body .ui.list .item .content .description.grayscale, #main.userportfolio .body .main .content-body .widgets .widget.volunteers .widget-body .ui.list .item .content .description.grayscale {
            color: #888;
            opacity: 0.7; }
          #main.userportfolio .body .main .content-body .widgets .widget.publications .widget-body .ui.list .item .content .header, #main.userportfolio .body .main .content-body .widgets .widget.volunteers .widget-body .ui.list .item .content .header {
            color: rgba(0, 0, 0, 0.7); }
          #main.userportfolio .body .main .content-body .widgets .widget.skills .widget-body span {
            margin-bottom: 5px;
            background: #74c0d0;
            color: #ffffff;
            padding: 10px;
            border-radius: 18px;
            position: relative; }
            #main.userportfolio .body .main .content-body .widgets .widget.skills .widget-body span.ui.label {
              font-size: 1.157143rem; }
        @media (min-width: 992px) {
          #main.userportfolio .body .main .content-body .widgets {
            padding-top: 0px;
            padding-right: 15px; } }

/* #main */
.no-website::-webkit-scrollbar {
  width: 0px !important; }

#main {
  /* .reports */ }
  #main.reports {
    overflow: hidden;
    /* .body */ }
    #main.reports .body {
      width: 100vw;
      height: 100vh;
      position: absolute;
      z-index: 100;
      left: -100vw;
      top: 0px;
      background: #ffffff;
      padding-left: 0px;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: all 0.5s ease-out 0s;
      -webkit-transition: all 0.5s ease-out 0s;
      -moz-transition: all 0.5s ease-out 0s;
      font-size: 14px;
      /* .main */ }
      @media (min-width: 992px) {
        #main.reports .body {
          overflow: hidden; } }
      #main.reports .body.visible {
        left: 0px;
        transition: all 0.5s ease-out 0s;
        -webkit-transition: all 0.5s ease-out 0s;
        -moz-transition: all 0.5s ease-out 0s; }
      #main.reports .body > .ui.grid {
        height: 100vh;
        margin-top: 0px; }
        #main.reports .body > .ui.grid .row > .column {
          padding: 1rem; }
        @media (min-width: 992px) {
          #main.reports .body > .ui.grid {
            margin-top: -1rem; } }
      #main.reports .body .main {
        background: transparent url("../../images/cereal.png") no-repeat center top;
        background-size: 100% auto;
        /* .reports-metrics.pages */ }
        @media (min-width: 768px) {
          #main.reports .body .main {
            padding: 30px 0px 0px 70px; } }
        @media (min-width: 768px) {
          #main.reports .body .main .title {
            margin-top: 0px; } }
        #main.reports .body .main .title .icono {
          position: relative;
          top: 2px; }
        #main.reports .body .main .title .subtitle {
          font-weight: normal; }
        #main.reports .body .main .title .subtitle-gray {
          color: #c2c2c2;
          margin-left: 10px; }
        #main.reports .body .main .content-charts.skejule-scroll {
          height: auto;
          overflow-x: hidden;
          min-height: 350px; }
        @media (min-width: 992px) {
          #main.reports .body .main .content-charts.skejule-scroll {
            height: calc(100vh - 125px); } }
        @media (max-width: 767px) {
          #main.reports .body .main .content-charts .charts {
            padding-left: 3em; } }
        #main.reports .body .main .content-charts .charts .card.chart-container .title {
          font-size: 14px; }
          #main.reports .body .main .content-charts .charts .card.chart-container .title .subtitle {
            font-weight: lighter;
            color: #c2c2c2;
            margin-left: 10px; }
            #main.reports .body .main .content-charts .charts .card.chart-container .title .subtitle .icon.help {
              font-size: 20px;
              top: 2px;
              position: relative; }
        #main.reports .body .main .content-charts .charts .card.chart-container .chart-paint.loading-chart {
          width: 100%;
          position: relative;
          height: 325px; }
        #main.reports .body .main .content-charts .charts .card.chart-container .chart-paint .chart-skejule .canvasjs-chart-container .canvasjs-chart-credit {
          display: none; }

/*#main*/
#chart-more-info-popup.ui.bottom {
  bottom: auto !important; }

#chart-more-info-popup h5 {
  font-weight: bold; }

#chart-more-info-popup .value {
  font-size: 1.4rem;
  color: #999; }
