.modal-create-company.reset-password button {
  font-size: 14px;
  line-height: 1.42857143; }

.modal-create-company.reset-password .ui.form input[type="password"] {
  font-size: 14px;
  line-height: 1.42857143; }
  .modal-create-company.reset-password .ui.form input[type="password"]:focus {
    border: 1px solid #74c0d0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(116, 192, 208, 0.6); }

.modal-create-company.reset-password .ui.form .field.success input[type="password"],
.modal-create-company.reset-password .ui.form .fields.success .field input[type="password"] {
  border: 1px solid #208a19 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(32, 138, 25, 0.6); }
  .modal-create-company.reset-password .ui.form .field.success input[type="password"]:focus,
  .modal-create-company.reset-password .ui.form .fields.success .field input[type="password"]:focus {
    border: 1px solid #208a19 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(32, 138, 25, 0.6); }

.modal-create-company.reset-password .ui.form .field.negative input[type="password"],
.modal-create-company.reset-password .ui.form .fields.negative .field input[type="password"] {
  border: 1px solid #c52626;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(197, 38, 38, 0.6);
  background: #fff; }
  .modal-create-company.reset-password .ui.form .field.negative input[type="password"]:focus,
  .modal-create-company.reset-password .ui.form .fields.negative .field input[type="password"]:focus {
    border: 1px solid #c52626;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(197, 38, 38, 0.6); }

.modal-create-company.reset-password .ui.form .field.warning input[type="password"],
.modal-create-company.reset-password .ui.form .fields.warning .field input[type="password"] {
  border: 1px solid #f5a623 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 166, 35, 0.6); }
  .modal-create-company.reset-password .ui.form .field.warning input[type="password"]:focus,
  .modal-create-company.reset-password .ui.form .fields.warning .field input[type="password"]:focus {
    border: 1px solid #f5a623 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 166, 35, 0.6); }

.modal-create-company.reset-password .ui.form .field .input img,
.modal-create-company.reset-password .ui.form .fields .field .input img {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  width: 1em;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }

.modal-create-company.reset-password .ui.button:disabled,
.modal-create-company.reset-password .ui.disabled.button {
  opacity: 0.4 !important;
  background: #999; }
