@font-face {
  font-family: 'skejule';
  src: url("fonts/skejule.eot?hftc38");
  src: url("fonts/skejule.eot?hftc38#iefix") format("embedded-opentype"), url("fonts/skejule.ttf?hftc38") format("truetype"), url("fonts/skejule.woff?hftc38") format("woff"), url("fonts/skejule.svg?hftc38#skejule") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icono-'],
[class*=' icono-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'skejule' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icono-youtube-border:before {
  content: '\e95a'; }

.icono-youtube-fill:before {
  content: '\e95b'; }

.icono-youtube:before {
  content: '\e95c'; }

.icono-instagram-border:before {
  content: '\e95d'; }

.icono-instagram-fill:before {
  content: '\e95e'; }

.icono-instagram:before {
  content: '\e95f'; }

.icono-twitter-border:before {
  content: '\e960'; }

.icono-twitter-fill:before {
  content: '\e961'; }

.icono-twitter:before {
  content: '\e962'; }

.icono-facebook-border:before {
  content: '\e963'; }

.icono-facebook-fill:before {
  content: '\e964'; }

.icono-facebook:before {
  content: '\e965'; }

.icono-linkedin-border:before {
  content: '\e966'; }

.icono-linkedin-fill:before {
  content: '\e967'; }

.icono-linkedin:before {
  content: '\e968'; }

.icono-hamburger-border:before {
  content: '\e90d'; }

.icono-hamburger-fill:before {
  content: '\e90e'; }

.icono-hamburger:before {
  content: '\e90f'; }

.icono-speechbubble-border:before {
  content: '\e910'; }

.icono-speechbubble-fill:before {
  content: '\e926'; }

.icono-speechbubble:before {
  content: '\e927'; }

.icono-caselog-border:before {
  content: '\e90c'; }

.icono-caselog-fill:before {
  content: '\e956'; }

.icono-caselog:before {
  content: '\e957'; }

.icono-back-border:before {
  content: '\e900'; }

.icono-back-fill:before {
  content: '\e901'; }

.icono-back:before {
  content: '\e902'; }

.icono-bell-border:before {
  content: '\e903'; }

.icono-bell-fill:before {
  content: '\e904'; }

.icono-bell:before {
  content: '\e905'; }

.icono-building-border:before {
  content: '\e906'; }

.icono-building-fill:before {
  content: '\e907'; }

.icono-building:before {
  content: '\e908'; }

.icono-calendar-border:before {
  content: '\e909'; }

.icono-calendar-fill:before {
  content: '\e90a'; }

.icono-calendar:before {
  content: '\e90b'; }

.icono-circles-border:before {
  content: '\e911'; }

.icono-circles-fill:before {
  content: '\e912'; }

.icono-circles:before {
  content: '\e913'; }

.icono-clipboard-border:before {
  content: '\e914'; }

.icono-clipboard-fill:before {
  content: '\e915'; }

.icono-clipboard:before {
  content: '\e916'; }

.icono-close-border:before {
  content: '\e917'; }

.icono-close-fill:before {
  content: '\e918'; }

.icono-close:before {
  content: '\e919'; }

.icono-document1-border:before {
  content: '\e91a'; }

.icono-document1-fill:before {
  content: '\e91b'; }

.icono-document1:before {
  content: '\e91c'; }

.icono-document2-border:before {
  content: '\e91d'; }

.icono-document2-fill:before {
  content: '\e91e'; }

.icono-document2:before {
  content: '\e91f'; }

.icono-download-border:before {
  content: '\e920'; }

.icono-download-fill:before {
  content: '\e921'; }

.icono-download:before {
  content: '\e922'; }

.icono-edit1-border:before {
  content: '\e923'; }

.icono-edit1-fill:before {
  content: '\e924'; }

.icono-edit1:before {
  content: '\e925'; }

.icono-etc-border:before {
  content: '\e929'; }

.icono-etc-fill:before {
  content: '\e92a'; }

.icono-etc:before {
  content: '\e92b'; }

.icono-exclamation-border:before {
  content: '\e92c'; }

.icono-exclamation-fill:before {
  content: '\e92d'; }

.icono-exclamation:before {
  content: '\e92e'; }

.icono-file-border:before {
  content: '\e92f'; }

.icono-file-fill:before {
  content: '\e930'; }

.icono-file:before {
  content: '\e931'; }

.icono-folder-border:before {
  content: '\e932'; }

.icono-folder-fill:before {
  content: '\e933'; }

.icono-folder:before {
  content: '\e934'; }

.icono-house-border:before {
  content: '\e935'; }

.icono-house-fill:before {
  content: '\e936'; }

.icono-house:before {
  content: '\e937'; }

.icono-phone-border:before {
  content: '\e938'; }

.icono-phone-fill:before {
  content: '\e939'; }

.icono-phone:before {
  content: '\e93a'; }

.icono-reload-border:before {
  content: '\e93b'; }

.icono-reload-fill:before {
  content: '\e93c'; }

.icono-reload:before {
  content: '\e93d'; }

.icono-search-border:before {
  content: '\e93e'; }

.icono-search-fill:before {
  content: '\e93f'; }

.icono-search:before {
  content: '\e940'; }

.icono-statistics-border:before {
  content: '\e941'; }

.icono-statistics-fill:before {
  content: '\e942'; }

.icono-statistics:before {
  content: '\e943'; }

.icono-tick-border:before {
  content: '\e944'; }

.icono-tick-fill:before {
  content: '\e945'; }

.icono-tick:before {
  content: '\e946'; }

.icono-trash-border:before {
  content: '\e947'; }

.icono-trash-fill:before {
  content: '\e948'; }

.icono-trash:before {
  content: '\e949'; }

.icono-upload-border:before {
  content: '\e94a'; }

.icono-upload-fill:before {
  content: '\e94b'; }

.icono-upload:before {
  content: '\e94c'; }

.icono-user-border:before {
  content: '\e94d'; }

.icono-user-fill:before {
  content: '\e94e'; }

.icono-user:before {
  content: '\e94f'; }

.icono-userplus-border:before {
  content: '\e950'; }

.icono-userplus-fill:before {
  content: '\e951'; }

.icono-userplus:before {
  content: '\e952'; }

.icono-usertick-border:before {
  content: '\e953'; }

.icono-usertick-fill:before {
  content: '\e954'; }

.icono-usertick:before {
  content: '\e955'; }

.icono-redcross-border:before {
  content: '\e928'; }

.icono-redcross-fill:before {
  content: '\e958'; }

.icono-redcross:before {
  content: '\e959'; }
